<template>
  <div>
    <b-modal
      v-model="deleteElement"
      :title="$t('Delete Fund')"
      :ok-title="$t('Delete')"
      :cancel-title="$t('cancel')"
      ok-variant="danger"
      cancel-variant="primary"
      @close="deleteElement=false"
      @cancel="deleteElement=false"
      @ok="ConfirmDelete"
    >
      <b-badge
        class="mt-1"
        variant="light-danger"
      >
        {{ $t('are you sure you want to delete this Fund') }}
      </b-badge>
    </b-modal>
    <h3 class="mb-3">
      {{ $t('Funds') }}
    </h3>
    <b-row>
      <b-col cols="4">
        <b-card :title="$t('Box')">
          {{ $t('Total') }} : {{ totalBalance }} ليرة نركي<br>
        </b-card>
      </b-col>
      <b-col cols="5">
        <b-button
          v-if="haveAccess('Create Fund')"
          variant="gradient-success"
          @click="$router.push({name:'fundd.create'})"
        >
          {{ $t('Create new havale') }}
        </b-button>
        <b-button
          v-if="haveAccess('Create Fundd')"
          variant="gradient-primary"
          class="m-1 w-50"
          @click="calc"
        >
          {{ $t('jard') }}
        </b-button>
      </b-col>
      <b-col
        cols="3"
        class="d-flex justify-content-end"
        style="width:100%; height: 50px;"
      >
        <b-button
          v-if="haveAccess(['Show Fundd Trash','Show Fundd Trash Owner'])"
          variant="gradient-danger"
          @click="$router.push({name:'fundd.trashed'})"
        >
          <font-awesome-icon
            :icon="['fas', 'trash']"
          />
          {{ $t('Trashed') }}
        </b-button>
      </b-col>
    </b-row>

    <b-card
      class="mt-5"
    >
      <b-card-title>
        <b-row>
          <b-col cols="8">
            <h3>
              {{ $t('Funds operation') }}
            </h3>
          </b-col>
          <b-col
            cols="4"
          >
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                :placeholder="$t('Type to Search')"
              />
            </b-input-group>
          </b-col>
        </b-row>
      </b-card-title>
      <b-table
        v-if="items.length>0"
        :fields="fields"
        responsive="sm"
        :items="items"
        :per-page="perPage"
        :filter="filter"
        :current-page="currentPage"
        class="table-w-max"
      >
        <template #cell(id)="data">
          <b-badge
            pill
            variant="light-primary"
          >
            {{ data.item.no }}
          </b-badge>
        </template>
        <template
          #cell(created_at_updated_at)="data"
        >
          <div>
            <b-badge
              v-if="data.item.created_at"
              v-b-tooltip.hover="{title:data.item.created_at,variant:'success',placement:'top'}"
              variant="light-success"
            >
              <font-awesome-icon
                :icon="['fas', 'table']"
              />
              {{ data.item.created_from }}
            </b-badge>
            <b-badge
              v-if="data.item.updated_from"
              v-b-tooltip.hover="{ title:data.item.updated_from,variant:'warning',placement:'top'}"
              variant="light-warning"
              class="ml-1"
            >
              <font-awesome-icon
                :icon="['fas', 'calendar-week']"
              />
              {{ data.item.updated_from }}
            </b-badge>
          </div>
        </template>
        <template #cell(operation)="data">
          <b-badge
            pill
            :variant="data.value?'light-success':'light-danger'"
          >   {{ data.value?'وارد':'صادر' }}
          </b-badge>
        </template>

        <template #cell(options)="data">
          <div class="w-max">
            <router-link
              v-if="haveAccess('Update Fundd')||haveElementAccess('Update Fundd Owner',data.item.created_by_id)"
              class="text-success"
              :to="{name:'fundd.edit',params: { fundd: data.item.id }}"
            >
              <font-awesome-icon
                :icon="['fas', 'edit']"
              />
            </router-link>
            <a
              v-if="haveAccess('Delete Fundd')||haveElementAccess('Delete Fundd Owner',data.item.created_by_id)"
              class="ml-2 text-danger"
              @click="Delete(data.item.id)"
            >
              <font-awesome-icon
                :icon="['fas', 'trash']"
              />
            </a>
          </div>
        </template>
      </b-table>
      <b-row
        v-else
        class="d-flex justify-content-center"
      >
        <b-col
          cols="4"
        >
          <b-alert
            variant="danger"
            show
          >
            <div class="alert-body text-center">
              <span>{{ $t('No Data Available') }}</span>
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end mt-2"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
    <explain />
  </div>
</template>

<script>

export default {
  data() {
    return {
      data: {},
      deleteElement: false,
      filter: null,
      element: null,
      perPage: 10,
      currentPage: 1,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: this.$t('id') },
        { key: 'amount', label: this.$t('amount') },
        { key: 'operation', label: this.$t('operation') },
        { key: 'reciever', label: this.$t('reciever') },
        { key: 'notes', label: this.$t('notes') },
        { key: 'created_at_updated_at', label: this.$t('created_at_updated_at') },
        { key: 'options', label: this.$t('options') },
      ]
    },
    items() {
      return this.$store.getters['fundd/GetElements'][0]
    },
    totalBalance() {
      return this.$store.getters['fundd/GetElements'][1]
    },
    totalRows() {
      return this.items.length
    },
  },
  created() {
    this.$store.dispatch('fundd/GetElements')
  },
  methods: {
    calc() {
      this.errors = []
      this.data.operation = true
      this.$store.dispatch('fundd/Create', this.data).then(() => {
        this.$store.dispatch('fundd/GetElements')
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
    Delete(val) {
      this.deleteElement = true
      this.element = val
    },
    ConfirmDelete() {
      this.$store.dispatch('fundd/Delete', this.element)
    },
  },
}
</script>
